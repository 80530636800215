<template>
  <div class="px-5">
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}"
      :ref="`print_${currentDetail.id}`"
      :id="`print_${currentDetail.id}`"
      class="navy-blue-border-1px border-15px">
        <div class="d-flex">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.processes') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.print_type') }}
          </div>
          
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Sample', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_print_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_id"
                :name="`order_fabric_ready_made_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
                :not-list="true"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_print_type_fabric_print_type_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2">
            <custom-multi-select
                :disabled="true"
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.press_type_id"
                :name="`order_fabric_print_type_fabric_process_${index}`"
                :not-list="true"
                :options="fabricPrintTypeOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.fabric_type') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('sample.to_who') }}
          </div>
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <input v-model="currentDetail.fabric_type" :class="{'is-invalid': !currentDetail.fabric_type}"
              :name="`order_fabric_ready_made_fabric_type_${index}`" :placeholder="$t('order.please_enter_fabric_type')" :required="true"
              :title="null" class="form-control form-control-sm" type="text">
          </div>
          <div class="flex-1 d-flex justify-content-center">
            <input
                v-model="currentDetail.to_who"
                :name="`order_fabric_ready_made_to_who_${index}`"
                :placeholder="$t('sample.to_who')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="text"
            >
          </div>
        </div>
        <date-detail-table-row
            :model="currentDetail"
        ></date-detail-table-row>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.amount') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.currency_unit') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.exchange_rate') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.vade') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit_price') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.total_price') }}
          </div>
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <input
                v-model="currentDetail.amount"
                :class="{'is-invalid': !+currentDetail.amount}"
                :name="`order_fabric_ready_made_amount_${index}`"
                :placeholder="$t('order.please_enter_quantity')"
                :required="false"
                :title="null"
                class="form-control form-control-sm"
                type="number"
            >
          </div>
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.currency_unit_id"
                :name="`currency_unit_id_${index}`"
                :not-list="true"
                :options="currencyOptions"
                :required="false"
                :title="null"
                size="sm"
                @save="onExpenseDetailCurrencyChange($event, index)"
            ></custom-multi-select>
          </div>
          <div class="flex-1 py-2 d-flex flex-column justify-content-center text-center navy-blue-border-right-1px">
            {{ currentDetail.exchange_rate }}
          </div>
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.expiry_id"
                :name="`expiry_${index}`"
                :not-list="true"
                :options="expirationTypeOptions"
                :required="false"
                :title="null"
                object-key="name"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex flex-column justify-content-center py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.price"
                  :class="{'is-invalid': !+currentDetail.price}"
                  :name="`price_${index}`"
                  :placeholder="$t('order.please_enter_unit_price')"
                  :required="false"
                  class="form-control form-control-sm"
                  min="0"
                  step="0.01"
                  type="number"
              />
            </div>
          </div>
          <div class="flex-1 py-2 text-center">
            {{ totalAmount(index) }} {{ currencyOptions[currentDetail.currency_unit_id] }}
          </div>
        </div>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_yarn_fabric_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="orderId"
          name="print-request"
          :send-mail-url="sendMailUrl(index)"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitOrderFabricPrint(index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import FabricDetailTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/FabricDetailTableRow";
import TestMethodRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/CurrencyTableRow";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import _ from "lodash";
import DateDetailTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/samples/layout/request-fabric-accessory/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricPrintTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow,
    TextareaInput, CurrencyTableRow, TestMethodRow, FabricDetailTableRow, DatePickerInput, CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    },
    previousModel: {
      required: true
    },
    defaultModel: {
      required: true,
    },
    yarnModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null
    },
    order: {
      required: true,
      default: null
    },
    currentInstructionTypeId: {
      required: true,
    },
    fabricPrintTypeOptions: {
      required: true,
    },
    fabricAdditionOptions: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    tolerances: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    selectedPrintTypeId: {
      required: false,
    },
    amountUnitOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitting: false
    }
  },
  methods: {
    onExpenseDetailCurrencyChange(payload, index) {
      let newValue = payload.newValue, oldValue = payload.oldValue;
      let rate = 1;
      this.modelProxy[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.currencyOptions) {
        rate = Number(this.currencyRateOptions[this.currencyOptions[newValue[0]]].rate);
      }
      this.modelProxy[index].exchange_rate = rate;
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/fabric-request/${self.orderFabricId}/print-request/${itemId}`,
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.amount = 0;
      copy.id = null;

      this.modelProxy.push(copy);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });

      item.fabric_request_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_flow_date = moment(item.painted_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_end_date = moment(item.painted_end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitOrderFabricPrint(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/fabric-request/${self.orderFabricId}/print-request`,
        contents: {
          order_print_requests: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      }).catch(error => {
        self.is_submitting = false;
        self.sweetAlertError(error);
      });
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      return function (index) {
        if (!this.modelProxy[index].amount) {
          return 0;
        }
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * this.modelProxy[index].price;
        return (+this.modelProxy[index].total_amount).toFixed(0) || 0 ;
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.press_type_id) return false;
          if (!+current.supplier_company_id) return false;
          if (!current.fabric_process_ids.length) return false;
          if (!current.dispatch_location_id) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.amount) return false;
          if (!+current.amount_unit_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.price) return false;
          if (!current.description) return false;
          if (!current.fabric_type) return false;
          return true;
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/fabric-request/${this.orderFabricId}/print-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    if (this.previousModel && this.previousModel.length > 0) {
      this.modelProxy.forEach(model => {
        model.tolerance_amount = this.maxTolerance;
        model.fabric_request_id = this.orderFabricId;
        model.press_type_id = this.selectedPrintTypeId;
        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.previousModel[0].supplier_company_id;
        }
      })
    }
    if(this.$route.query.qr_type=="request-prints"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["print_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 1000);
    }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          newValue.forEach(model => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.fabric_request_id = this.orderFabricId;
            model.press_type_id = this.selectedPrintTypeId;
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      this.modelProxy.forEach(model => {
        model.fabric_request_id = value;
        if (!model.amount) {
          model.amount = _.sumBy(this.previousModel, 'amount');
        }
      })
    },
    selectedPrintTypeId(value) {
      this.modelProxy.forEach(model => {
        model.press_type_id = value;
      })
    },
    tolerances(value) {
      if (value) {
        this.modelProxy.forEach(model => {
          model.tolerance_amount = this.tolerances ? this.tolerances[model.press_type_id] : 1;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>

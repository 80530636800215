<template>
  <div class="card card-custom py-0">
    <div class="card-body card-body-scroll py-0 pb-2">
      <div class="bg-white">
        <div class="d-flex flex-column items-center justify-content-center w-100">
          <div class="d-block align-content-center">
            <p class="text-center mb-0">
            <span v-html="getIconByKey('icons.menu.order', {
                        class: 'w-75px h-75px object-cover'
                  })">
            </span>
            </p>
          </div>
          <h4 class="text-center w-100 text-primary font-weight-boldest"
              style="text-align: center; margin-top: -10px;">
            {{ $t("general.instructions") }}
          </h4>
          <br>
        </div>

        <div class="w-100 border-15px navy-blue-border-1px mx-3 mb-1" style="max-height: 40vh; overflow: scroll">
          <table class="table w-100 border-15px mb-0">
            <thead class="w-100">
            <tr>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.id') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{$t('general.supplier_company_name')}}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{$t('general.fabric')}}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{$t('sample.color')}}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{$t('general.instruction_type ')}}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.amount') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.remaining_amount ') }}
                </div>
              </th>
              <th class=" navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Link
                </div>
              </th>
            </tr>
            </thead>
            <tbody class="navy-blue-bottom-1px">
            <tr v-for="(item, index) in formattedInstructions" :key="index">
              <td class="navy-blue-border-right-1px items-start">
                {{ ++index}}
              </td>
              <td class="navy-blue-border-right-1px">
               {{ item.supplier_company.name}}
              </td>
              <td class="navy-blue-border-right-1px">
               {{ item.order_fabric ? item.order_fabric.type : '-' }}
              </td>
              <td class="navy-blue-border-right-1px">
                {{ item.order_size_and_color ? item.order_size_and_color.color_name : '-' }}
              </td>
              <td class="navy-blue-border-right-1px">
               {{ getInstructionType(item.tmp_type)}}
              </td>
              <td class="navy-blue-border-right-1px">
                {{ Number(item.total_amount) | amountFormat  }} {{ getUnitNameByOrderFabric(item) || 'KG' }}
              </td>
              <td class="navy-blue-border-right-1px">
                {{ (Number(item.total_amount) - Number(item.incoming_amount)) | amountFormat }} {{ getUnitNameByOrderFabric(item) || 'KG' }}
              </td>
              <td class="">
                <div class="d-flex justify-content-start">
                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.pdf_link" target="_blank">İndir</a>

                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.order_pdf_link" target="_blank" v-if="item.order_pdf_link">Firma Bazlı İndir</a>

                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.model_pdf_link" target="_blank" v-if="item.model_pdf_link">Firma Bazlı İndir</a>

                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.accessory_pdf_link" target="_blank" v-if="item.accessory_pdf_link">Firma Bazlı İndir</a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="bg-white" v-if="formattedAccessories && formattedAccessories.length">
        <div class="d-flex flex-column items-center justify-content-center w-100">
          <div class="d-block align-content-center">
            <p class="text-center mb-0">
            <span v-html="getIconByKey('icons.menu.order', {
                        class: 'w-75px h-75px object-cover'
                  })">
            </span>
            </p>
          </div>
          <h4 class="text-center w-100 text-primary font-weight-boldest"
              style="text-align: center; margin-top: -10px;">
            {{ $t("general.expenses") }}
          </h4>
          <br>
        </div>

        <div class="w-100 border-15px navy-blue-border-1px mx-3 mb-1" style="max-height: 40vh; overflow: scroll">
          <table class="table w-100 border-15px mb-0">
            <thead class="w-100">
            <tr>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.id') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Tedarikçi Firma
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Talimat Türü
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.amount') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Kalan Miktar
                </div>
              </th>
              <th class=" navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Link
                </div>
              </th>
            </tr>
            </thead>
            <tbody class="navy-blue-bottom-1px">
            <tr v-for="(item, index) in formattedAccessories">
              <td class="navy-blue-border-right-1px items-start">
                {{ ++index}}
              </td>
              <td class="navy-blue-border-right-1px">
               {{ item.supplier_company.name}}
              </td>
              <td class="navy-blue-border-right-1px">
               {{ getInstructionType(item.tmp_type)}}
              </td>
              <td class="navy-blue-border-right-1px">
                {{ Number(item.total_amount) | amountFormat  }} {{ getUnitName(item.amount_unit_id) || '' }}
              </td>
              <td class="navy-blue-border-right-1px">
                {{ (Number(item.total_amount) - Number(item.incoming)) | amountFormat }} {{ getUnitName(item.amount_unit_id) || '' }}
              </td>
              <td class="">
                <div class="d-flex justify-content-start">
                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.pdf_link" target="_blank">İndir</a>

                  <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.accessory_pdf_link" target="_blank" v-if="item.accessory_pdf_link">Firma Bazlı İndir</a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bg-white" v-if="formattedExpenses && formattedExpenses.length">
        <div class="d-flex flex-column items-center justify-content-center w-100">
          <div class="d-block align-content-center">
            <p class="text-center mb-0">
            <span v-html="getIconByKey('icons.menu.order', {
                        class: 'w-75px h-75px object-cover'
                  })">
            </span>
            </p>
          </div>
          <h4 class="text-center w-100 text-primary font-weight-boldest"
              style="text-align: center; margin-top: -10px;">
            {{ $t("general.expenses") }}
          </h4>
          <br>
        </div>

        <div class="w-100 border-15px navy-blue-border-1px mx-3 mb-1" style="max-height: 40vh; overflow: scroll">
          <table class="table w-100 border-15px mb-0">
            <thead class="w-100">
            <tr>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.id') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Tedarikçi Firma
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Masraf Türü
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Renk
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  {{ $t('general.amount') }}
                </div>
              </th>
              <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Kalan Miktar
                </div>
              </th>
              <th class=" navy-blue-border-bottom-1px">
                <div class="d-flex justify-content-center items-center text-lg">
                  Link
                </div>
              </th>
            </tr>
            </thead>
            <tbody class="navy-blue-bottom-1px">
            <template v-for="(expenseItem, expenseIndex) in formattedExpenses">
              <tr v-for="(item, index) in expenseItem.order_expense_details" :key="index">
                <td class="navy-blue-border-right-1px items-start">
                  {{ expenseIndex++ +  ++index}}
                </td>
                <td class="navy-blue-border-right-1px">
                  {{ item.supplier_company.name}}
                </td>
                <td class="navy-blue-border-right-1px">
                  {{expenseItem.expense.translations[0].name}}
                </td>

                <td class="navy-blue-border-right-1px">
                  {{item.order_size_and_color ? item.order_size_and_color.color_name : '-' }}
                </td>
                <td class="navy-blue-border-right-1px">
                  {{ Number(item.total_amount) | amountFormat }} {{ getUnitName(expenseItem.unit_id) || '' }}
                </td>
                <td class="navy-blue-border-right-1px">
                  {{ Number(item.remaining_amount)  | amountFormat }} {{ getUnitName(expenseItem.unit_id) || '' }}
                </td>
                <td class="">
                  <div class="d-flex justify-content-start">
                    <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.order_pdf_link" target="_blank" v-if="item.order_pdf_link">İndir</a>

                    <a class="btn btn-sm btn-success rounded-full mx-1" :href="item.model_pdf_link" target="_blank" v-if="item.model_pdf_link">Firma Bazlı İndir</a>
                  </div>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button
            type="button"
            @click="$emit('onCloseModal')"
            class="btn btn-outline-primary font-weight-bold cursor-pointer ml-4 text-uppercase rounded-full ">
          <span class="font-weight-boldest" style="font-weight: bolder;">{{ $t('general.close') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "instructionsModal",
  props: {
    items: {
      required: true,
      type: Array,
    },
    expenses: {
      required: false,
    },
    instructionTypes: {
      required: true,
      type: Array,
    },
    units: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      //instructionTypesArray: [],
    }
  },
  computed: {
    formattedInstructions() {
      let instructions = [];
      if (this.items) {
        this.items.forEach(item => {
          if (item.hasOwnProperty('incoming')) {
            item.incoming_amount = item.incoming;
          }

          if (item.hasOwnProperty('fabric_request_id') || item.hasOwnProperty('order_id')) {

            if (item.hasOwnProperty('fabric_request_id')) {

              item.order_fabric = {
                type: item.fabric_request.sample_product_informations.fabric_type.translations[0].name
              }
              item.order_size_and_color = {
                color_name: item.fabric_request.sample_product_informations.color
              }
            }
            instructions.push(item);
          }
        })
      }
      return instructions;
    },

    formattedAccessories() {
      let instructions = [];

      if (this.items) {
        this.items.forEach(item => {
          if (item.hasOwnProperty('accessory_request_id')) {
            instructions.push(item);
          }
        })
      }
      return instructions;

    },
    formattedExpenses() {
      let instructions = [];

      if (this.expenses) {
        this.expenses.forEach(item => {
          instructions.push(item);
        })
      }
      return instructions;
    },
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    }
  },
  methods: {
    getInstructionType(id) {
      if (id) {
        return this.instructionTypes.find(item => +item.id === +id).translations[0].name;
      }
    },
    getUnitName(id) {
      if (id) {
        return this.units.find(item => +item.id === +id).code;
      }
    },
    getUnitNameByOrderFabric(item) {
      if (item && item.hasOwnProperty('amount_unit_id')) {
        return this.units.find(unitItem => +unitItem.id === +item.amount_unit_id) ? this.units.find(unitItem => +unitItem.id === +item.amount_unit_id).code : null;
       } else if (item) {
        let orderFabricUnitId = item.order_fabric ? item.order_fabric.unit_id : null;
        return orderFabricUnitId ? this.units.find(item => +item.id === +orderFabricUnitId).code : null;
      }
    },
    log(){
      console.log(this.items);
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.radio.radio-primary > input:checked ~ span::after {
  background-color: $primary;
}
.user-image {
  border-radius: 50%;
}
</style>

<template>
  <div class="px-4">
    <div class="row" :class="{'mt-5': index !== 0}" v-for="(value, index) in modelProxy.detail">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <number-input
            :title="$t('sample.amount')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}amount_helper_text`)"
            :model.sync="value.amount"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">

      <custom-multi-select
            :max="1"
            :options="unitOptions"
            :title="$t('model_house.unit_name')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}amount_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.amount_unit_id"
            :is-inline="false"
            :input-width="100"
            name="amount_unit_id"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :options="processes"
            :title="$t('sample.proses')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}proses_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.fabric_process_id"
            :input-width="200"
            :is-inline="false"
            name="processes"
        ></custom-multi-select>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 px-0">
        <custom-multi-select
            :max="1"
            :options="supplierCompanies"
            :title="$t('sample.supplier_company')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}supplier_company_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.supplier_company_id"
            :input-width="200"
            :is-inline="false"
            name="supplier_company"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12" v-if="type === 1">
        <date-picker-input
            :title="$t('sample.raw_flow_date')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}raw_flow_date_helper_text`)"
            :name="`raw_flow_date_${index}`"
            :is-inline="false"
            :model.sync="value.raw_flow_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12" v-if="type === 1">

        <date-picker-input
            :title="$t('sample.raw_end_date')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}raw_end_date_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}raw_end_date_helper_text`)"
            :name="`raw_end_date_${index}`"
            :is-inline="false"
            :model.sync="value.raw_end_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12" >
        <date-picker-input
            :title="$t('sample.painted_flow_date')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}painted_flow_date_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}painted_flow_date_helper_text`)"
            :name="`raw_flow_date_${index}`"
            :is-inline="false"
            :model.sync="value.painted_flow_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <date-picker-input
            :title="$t('sample.painted_end_date')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}painted_end_date_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}painted_end_date_helper_text`)"
            :name="`raw_end_date_${index}`"
            :is-inline="false"
            :model.sync="value.painted_end_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :input-width="200"
            :options="dispatchLocations"
            :title="$t('sample.dispatch_location')"
            :placeholder="$t('sample.multi_select')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}dispatch_location_helper_text`)"
            :is-inline="false"
            :model.sync="value.dispatch_location_id"
            :name="`dispatch_location_${index}`"
            :not-list="true"
        ></custom-multi-select>

      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">

        <number-input
            :title="$t('sample.price')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}price_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}price_helper_text`)"
            :model.sync="value.price"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">

        <custom-multi-select
            :max="1"
            :options="currencyOptions"
            :title="$t('sample.price')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}price_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.currency_unit_id"
            :is-inline="false"
            input-width="100"
            name="supplier_company"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="expirationTypeOptions"
            :title="$t('sample.payment_method')"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.expiry_id"
            :is-inline="false"
            input-width="100"
            object-key="name"
            :item-per-row="2"
            name="expiration_type_options"
            :not-list="true"
            :show-invalid="false"
            :required="false"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <text-input
            :title="$t('sample.to_who')"
            :helper-text="$t('sample.to_who_helper_text')"
            :placeholder="$t('sample.to_who_helper_text')"
            :name="`to_who_${index}`"
            :is-inline="false"
            :model.sync="value.to_who"
            :required="false"
        ></text-input>
      </div>
      <div class="w-100 d-flex  mb-3 px-4" :class="index > 0 ? 'justify-content-between' : 'justify-content-end' ">

        <div v-if="index > 0">
        <button-with-icon
            v-if="index > 0"
            @onClick="deleteItem(index)"
            icon-name="icons.sample.delete_red"
            class="navy-blue-border-1px"
            :text="$t('general.delete')"
        ></button-with-icon>
          </div>
        <button-with-icon
            @onClick="value.showDescription = true"
            icon-name="icons.sample_instruction.add_2"
            class="navy-blue-border-1px"
            :text="$t('sample.add_description')"
        ></button-with-icon>
      </div>
      <div class="pt-5 pb-2 navy-blue-border-1px border-15px mb-4 w-100 mx-4 px-3"
           v-if="value.showDescription || value.description">
        <textarea-input
            :title="null"
            :model.sync="value.description"
            :required="false"
        ></textarea-input>
        <div class="w-50px ">
        <button-with-icon
          @onClick="hideShowDescription(index)"
          icon-name="icons.sample.delete_red"
          class="navy-blue-border-1px"
          :text="$t('general.delete')"
      ></button-with-icon>
          </div>
      </div>
      <template v-if="Number(type) === 1">
        <div class="d-flex justify-content-center items-center my-4">
                  <span v-html="getIconByKey('icons.sample_instruction.question', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
          <p class="mb-0 font-weight-bolder text-navy-blue text-xl bottom-border-1px-navy">
            {{ $t('sample.please_select_product_processes') }}
          </p>
        </div>

        <div class="">
          <div class="row w-100 pt-3 pb-1 border-15px d-flex justify-content-center">
            <div class="col-lg-2 col-md-3 col-sm-4 px-2 text-center mb-2" v-for="(process, key) in productProcess">
                <span class="w-100 d-inline-block py-2 border-15px font-weight-boldest cursor-pointer text-md"
                      @click="addProductProcess(index, key)"
                      :class="{
                    'green-border-1px text-primary': isProcessSelected(index, key),
                    'navy-blue-border-1px': !isProcessSelected(index, key)
                  }"
                >{{ process }}</span>
            </div>
          </div>
        </div>
      </template>

      <div class="bottom-border-1px-navy py-1 w-100 opacity-50"
           v-if="(index !== modelProxy.detail.length - 1)"></div>
    </div>
    <div class="w-100 d-flex justify-content-between mb-1" >

      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_supplier')"
      ></button-with-icon>
      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_dispatch_location')"
      ></button-with-icon>
    </div>
    <div class="w-100 my-7 d-flex justify-content-center">
      <div class="bottom-border-1px-navy mx-auto d-inline-block">
        <span class="d-inline-block text-2xl mr-10 font-weight-bolder">{{ $t('sample.total_price') }}</span>
        <span class="text-2xl">{{ totalPrice }}
          {{ modelProxy.detail[0].currency_unit_id ? currencyOptions[modelProxy.detail[0].currency_unit_id] : ''}}
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import NumberInput from "@/assets/components/inputs/NumberInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import TextInput from "@/assets/components/inputs/TextInput";
import BottomButtons from "./BottomButtons";

export default {
  components: {
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DatePickerInput,
    NumberInputWithUnit,
    TextareaInput,
    ButtonWithIcon,
    BottomButtons
  },
  name: "ReadMadeAndPaintForm",
  props: {
    model: {
      required: true,
    },
    supplierCompanies: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    expiryId: {
      required: false,
    },
    supplierCompanyId: {
      required: false,
    },
    currencyOptions: {
      required: true,
    },
    type: {
      required: true,
      validation: function(l){
        return [1, 2].indexOf(l) > -1; // 1 => Yarn, 2 => Ready Made
      }
    },
    dispatchLocations: {
      required: true,
    },
    processes: {
      required:true,
    },
    unitOptions: {
      required: true,
    },
    currency_unit_id: {
      required: true,
    },
    productProcess: {
      required: true,
      type: Object
    }
  },

  data(){
    return{
      value:[]

    }
  },
  computed: {

    isProcessSelected(){
      const self = this;
      return function(index, key){
        for(let i = 0; i < self.modelProxy.detail[index].product_processes.length; i++){
          const current = self.modelProxy.detail[index].product_processes[i];
          if(Number(current.product_process_id) === Number(key)){
            return true;
          }
        }
        return false;
      }
    },
    modelProxy: {
      get(){
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    totalPrice(){
      let total = 0;
      for(let i = 0; i < this.modelProxy.detail.length; i++){
        total += Number(this.modelProxy.detail[i].amount) * Number(this.modelProxy.detail[i].price);
      }
      return total.toFixed(2);
    }
  },

  methods: {

    hideShowDescription(index){
      console.log('this is new description')
      this.modelProxy.detail[index].description = '';
      this.modelProxy.detail[index].showDescription  = false;

    },
    addProductProcess(index, key){

      let exist_key = null;
      for(let i = 0; i < this.modelProxy.detail[index].product_processes.length; i++){
        const current = this.modelProxy.detail[index].product_processes[i];
        if(Number(current.product_process_id) === Number(key)){
          exist_key = i;
          break;
        }
      }
      if(exist_key != null){
        this.modelProxy.detail[index].product_processes.splice(exist_key, 1);
      }else{
        this.modelProxy.detail[index].product_processes.push({
          product_process_id: key
        });
      }
    },
    addMore(){
      this.modelProxy.detail.push({...this.modelProxy.detail.slice(-1)[0], showDescription: false, description: null})
    },

    deleteItem(index){
      this.modelProxy.detail.splice(index,1);
    }
  },
  mounted() {

  }

}
</script>

<style scoped>

</style>

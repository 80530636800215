<template>
  <div v-if="taskStatus" class="m-0 p-0">
    <modal :adaptive="true" height="auto" name="display-excel-modal" width="75%">
      <div class="row">
        <div class="col-md-12" style="overflow: auto; max-height: 75vh;">
          <table class="table table-bordered table-striped table-hover table-condensed ">
            <thead>
            <tr>
              <th v-for="item in currentExcelInfo.headers">{{ item }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in currentExcelInfo.body">
              <td v-for="key in item">
                <label>{{ key }}</label>
                <p>{{ item.key }}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" height="auto" name="display-doc-modal" width="75%">
      <div class="row">
        <div class="col-md-12" style="overflow: auto; max-height: 75vh;">
          <table class="table table-bordered table-striped table-hover table-condensed ">
            <thead>
            <tr>
              <th v-for="item in currentDocInfo.headers">{{ item }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in currentDocInfo.body">
              <td v-for="key in item">
                <label>{{ key }}</label>
                <p>{{ item.key }}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" height="auto" name="display-pdf-modal" width="75%">
      <div class="row">
        <div class="col-md-12" style="overflow: auto; max-height: 75vh;">
          <pdf
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
              style="display: inline-block; width: 100%"
          ></pdf>
        </div>
      </div>
    </modal>

    <template v-if="Number(taskStatus) === 14">
      <div class="col-12 row mt-10">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
          <date-picker-input
              :disabled="true"
              :is-valid="true"
              :title="$t('model_house.action_date')"
          >
          </date-picker-input>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
          <custom-multi-select
              :helper-text="$t('model_house.please_select_product_identification')"
              :max="1"
              :model.sync="form.product_description"
              :not-list="true"
              :options="productDescriptionOptions"
              :placeholder="$t('model_house.multi_select')"
              :title="$t('model_house.product_identification')"
              inputWidth="115px"
              name="wayBillExitStateProductIdentification"
          >
          </custom-multi-select>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
          <number-input
              :helper-text="$t('model_house.please_enter_entry_net_amount')"
              :min="0"
              :model.sync="form.net_amount"
              :step="1"
              :title="$t('model_house.entry_net_amount')"
          ></number-input>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
          <custom-multi-select
              :helper-text="$t('model_house.select_unit_name')"
              :max="1"
              :model.sync="form.unit_id"
              :not-list="true"
              :options="unitOptions"
              :placeholder="$t('model_house.multi_select')"
              :title="$t('model_house.unit_name')"
              inputWidth="115px"
              name="wayBillExitStateProductIdentification"
          >
          </custom-multi-select>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
          <number-input
              :helper-text="$t('model_house.please_enter_number_of_income_roll')"
              :min="0"
              :model.sync="form.fabric_roll"
              :step="1"
              :title="$t('model_house.number_of_income_roll')"
          ></number-input>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
          <text-input
              :helper-text="$t('model_house.please_select_product_name')"
              :model.sync="form.product_name"
              :title="$t('model_house.product_name')"
          ></text-input>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
          <text-input
              :helper-text="$t('model_house.please_select_product_color')"
              :model.sync="form.product_color"
              :title="$t('model_house.product_color')"
          ></text-input>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">

          <text-input
              :helper-text="$t('model_house.please_select_order_number')"
              :model.sync="form.order_number"
              :title="$t('model_house.order_no')"
          ></text-input>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">

          <number-input
              :helper-text="$t('model_house.please_enter_entry_part_number')"
              :is-valid="Number(form.part_number) !==  0 ? true : null"
              :min="0"
              :model.sync="form.part_number"
              :step="1"
              :title="$t('model_house.entry_part_number')"
          ></number-input>
        </div>
      </div>
      <div class="col-12 row d-flex justify-content-center mt-5">
        <button-with-icon
            :disabled="!isValidToSubmitReceiveMaterial && is_submitting && !isUserGranted('ModelHouse', ['update'], false)"
            :icon-name="null"
            :text="$t('model_house.save').toUpperCase()"
            class="font-weight-boldest"
            size="lg"
            @onClick="submissionForReceiveMaterials"
        ></button-with-icon>
      </div>
    </template>
    <template v-if="Number(taskStatus) !== 14">
      <h3 v-b-toggle.designer_info_entry class="top-border-1px-navy text-center mt-5">
        <span v-html="getIconByKey('icons.model_house.down_arrow',{
          class: 'w-40px h-40px object-fill d-inline-block -mt-5'
        })"></span>
      </h3>
      <b-collapse id="designer_info_entry" class="pt-5" visible>

        <h3 class="cursor-pointer pb-2 mb-5 d-flex justify-content-center"
            variant="info">
          <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.model_house.sewing_machine', {
                class: 'w-30px h-30px object-fill d-inline-block mr-2'
              })"
              ></span>
            <span>{{ $t('model_house.planter_description') }}</span>
            <i class="fas fa-caret-down ml-3"></i>
          </div>
        </h3>
        <h4 v-if="Number(taskStatus) === 8" class="text-center text-primary font-weight-normal my-3">
          "{{ $t('model_house.please_select_entry_method') }}"</h4>

        <div v-if="Number(taskStatus) === 8" class="d-flex justify-content-center ">
          <button-with-icon
              :active="true"
              :disabled="selectedEntry !== 1"
              :text="$t('model_house.manual')"
              class="mr-3"
              icon-name="icons.model_house.manual"
              size="lg"
              @onClick="selectedEntry = 1"
          ></button-with-icon>
          <button-with-icon
              :active="true"
              :disabled="selectedEntry !== 2"
              :text="$t('model_house.upload_file')"
              class="mr-3"
              icon-name="icons.model_house.create_excel"
              size="lg"
              @onClick="selectedEntry = 2"
          ></button-with-icon>
          <button-with-icon
              :active="true"
              :disabled="selectedEntry !== 3"
              :text="$t('model_house.customer_mold')"
              class="mr-3"
              icon-name="icons.model_house.create_pdf"
              size="lg"
              @onClick="selectedEntry = 3"
          ></button-with-icon>
          <button-with-icon
              :active="true"
              :disabled="selectedEntry !== 4"
              :text="$t('model_house.customer_mold')"
              class="mr-3"
              icon-name="icons.model_house.create_doc"
              size="lg"
              @onClick="selectedEntry = 4"
          ></button-with-icon>
        </div>
        <template v-if="selectedEntry === 1">
          <table class="table table-bordered my-5">
            <thead>
            <tr>
              <th class="border border-top-0 text-center  border-bottom-0 w-20px">{{ $t('model_house.alphabets') }}</th>
              <th class="border border-top-0 text-center border-bottom-0 w-150px ">
                {{ $t('model_house.measurement_place') }}
              </th>
              <th class="border border-top-0 text-center border-bottom-0 w-150px ">
                {{ $t('model_house.description') }}
              </th>
              <th :colspan="(Object.keys(designerForm.sewing_info.C11.sizes).length || 1)"
                  class="text-center py-0 border-bottom-0">
                {{ $t('model_house.sizes') }}
                <div class="d-flex w-100">
                  <input v-model="newSewingSizeRow" :disabled="Number(taskStatus) !== 8" :placeholder="$t('model_house.please_enter_name_for_size')"
                         class="font-weight-normal flex-grow-1"
                         type="text">
                  <span class="cursor-pointer" @click="addNewSewingSizeRow"
                        v-html="getIconByKey('icons.model_house.add', {})"> test</span>
                </div>
              </th>
            </tr>
            <tr>
              <th class="border border-top-0"></th>
              <th class="border border-top-0"></th>
              <th class="border border-top-0"></th>
              <th v-for="(size, index) in designerForm.sewing_info.C11.sizes" class="text-center text-lg py-1">
                {{ index }} <span class="cursor-pointer" @click="removeSewingSizeRowByKey(index)" v-html="getIconByKey('icons.model_house.delete', {
                  class: 'h-25px w-25px object-cover ml-2'
                })"></span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, key) in designerForm.sewing_info" :key="key">
              <td class="font-weight-boldest text-center text-sm">{{ key }}</td>
              <td><input v-model="row.measurement_place" :disabled="Number(taskStatus) !== 8" :placeholder="capitalize($t('model_house.measurement_place'))"
                         class="w-100 text-center text-sm"
                         type="text"></td>
              <td><input v-model="row.description" :disabled="Number(taskStatus) !== 8" :placeholder="capitalize($t('model_house.description'))"
                         class="w-100 text-center text-sm" type="text">
              </td>
              <td v-if="Object.keys(row.sizes).length === 0"></td>
              <td v-for="(size, index) in row.sizes" :key="index"><input v-model="row.sizes[index]"
                                                                         :disabled="Number(taskStatus) !== 8" :placeholder="$t('model_house.please_enter')"
                                                                         class="w-100 text-sm text-center"
                                                                         type="text">
              </td>
            </tr>
            </tbody>
          </table>
        </template>
        <template v-if="selectedEntry === 2 || Number(taskStatus) !== 8">
          <div class="mt-5 row">
            <div v-for="(file, index) in excels" class="col-lg-2 col-md-3 col-sm-4 justify-content-center">
              <span class="d-block text-center rounded-full cursor-pointer" @click="openExcel(index)" v-html="getIconByKey('icons.model_house.excel_icon', {
                class: 'w-75px h-75px object-cover d-inline-block'
              })"></span>
              <span class="text-center d-block font-weight-boldest">{{ file.name }}</span>
              <span class="d-block text-center mt-1 cursor-pointer" @click="removeSelectedFile(index, 1)" v-html="getIconByKey('icons.model_house.delete', {
                class: 'w-25px h-25px object-cover d-inline-block'
              })"></span>
            </div>
          </div>
          <div v-if="Number(taskStatus) === 8" class="d-flex justify-content-center mt-5">
            <file-input
                ref="excelFileInput"
                :model.sync="currentSelectedExcel"
                :multiple="true"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                name="upload_excel_file"
                @fileChange="onExcelUpload"
            >
              <template slot="inputDesign">
                <button-with-icon
                    :text="$t('model_house.please_select_excel_files_to_upload')"
                    icon-name="icons.model_house.upload_from_file"
                    @onClick="$refs.excelFileInput.openFileInput()"
                ></button-with-icon>
              </template>
            </file-input>
          </div>
        </template>
        <template v-if="selectedEntry === 3 || Number(taskStatus) !== 8">
          <div class="mt-5 row">
            <div v-for="(file, index) in pdfs" class="col-lg-2 col-md-3 col-sm-4 justify-content-center">
              <span class="d-block text-center rounded-full cursor-pointer" @click="openPDF(index)" v-html="getIconByKey('icons.model_house.pdf_icon', {
                class: 'w-75px h-75px object-cover d-inline-block'
              })"></span>
              <span class="text-center d-block font-weight-boldest">{{ file.name }}</span>
              <span class="d-block text-center mt-1 cursor-pointer" @click="removeSelectedFile(index, 2)" v-html="getIconByKey('icons.model_house.delete', {
                class: 'w-25px h-25px object-cover d-inline-block'
              })"></span>
            </div>
          </div>
          <div v-if="Number(taskStatus) === 8" class="d-flex justify-content-center mt-5">
            <file-input
                ref="PDFFileInput"
                :model.sync="currentSelectedPDF"
                :multiple="true"
                accept="application/pdf"
                name="upload_pdf_file"
                @fileChange="onPDFUpload"
            >
              <template slot="inputDesign">
                <button-with-icon
                    :text="$t('model_house.please_select_pdf_files_to_upload')"
                    icon-name="icons.model_house.upload_from_file"
                    @onClick="$refs.PDFFileInput.openFileInput()"
                ></button-with-icon>
              </template>
            </file-input>
          </div>
        </template>
        <template v-if="selectedEntry === 4 || Number(taskStatus) !== 8">
          <div class="mt-5 row">
            <div v-for="(file, index) in docs" class="col-lg-2 col-md-3 col-sm-4 justify-content-center">
              <span class="d-block text-center rounded-full cursor-pointer" @click="downloadDoc(file)" v-html="getIconByKey('icons.model_house.excel_icon', {
                class: 'w-75px h-75px object-cover d-inline-block'
              })"></span>
              <span class="text-center d-block font-weight-boldest">{{ file.name }}</span>
              <span class="d-block text-center mt-1 cursor-pointer" @click="removeSelectedFile(index, 3)" v-html="getIconByKey('icons.model_house.delete', {
                class: 'w-25px h-25px object-cover d-inline-block'
              })"></span>
            </div>
          </div>
          <div v-if="Number(taskStatus) === 8" class="d-flex justify-content-center mt-5">
            <file-input
                ref="docsFileInput"
                :model.sync="currentSelectedDoc"
                :multiple="true"
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="upload_doc_file"
                @fileChange="onDocUpload"
            >
              <template slot="inputDesign">
                <button-with-icon
                    :text="$t('model_house.please_select_docs_files_to_upload')"
                    icon-name="icons.model_house.upload_from_file"
                    @onClick="$refs.docsFileInput.openFileInput()"
                ></button-with-icon>
              </template>
            </file-input>
          </div>
        </template>
        <template v-if="selectedEntry !== null">
          <div class="px-5 my-5 w-100">
            <template v-for="content in contents"
                      v-if="(contents !== null && contents.length !== 0)"
            >
              <template v-if="Number(content.content_type_id) === 1">
                <div class="row py-5">
                  <div class="col-12">
                    <meeting-text-holder-card :content="content" :is-deletable="Number(content.user_id) === Number(authUser.user_id) && !is_submitting"
                                              @onClickDelete="deleteContent"></meeting-text-holder-card>
                  </div>
                </div>
              </template>

              <template v-if="Number(content.content_type_id) === 2">
                <div class="row py-5">
                  <div class="col-12">
                    <meeting-video-holder-card :content="content" :is-deletable="Number(content.user_id) === Number(authUser.user_id) && !is_submitting"
                                               @onClickDelete="deleteContent"></meeting-video-holder-card>
                  </div>
                </div>
              </template>

              <template v-if="Number(content.content_type_id) === 3">
                <div class="row py-5">
                  <div class="col-12">
                    <meeting-image-holder-card :content="content" :is-deletable="Number(content.user_id) === Number(authUser.user_id) && !is_submitting"
                                               @onClickDelete="deleteContent"></meeting-image-holder-card>
                  </div>
                </div>
              </template>

              <template v-if="Number(content.content_type_id) === 4">
                <div class="row py-5">
                  <div class="col-12">
                    <meeting-audio-player-holder-card :content="content"
                                                      :is-deletable="Number(content.user_id) === Number(authUser.user_id) && !is_submitting"
                                                      @onClickDelete="deleteContent"></meeting-audio-player-holder-card>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="Number(taskStatus) === 30">
              <h1 class="text-center mb-lg-10">{{ $t('model_house.collection_sample_canceled') }}</h1>
            </template>
            <template v-if="contents === null || contents.length === 0">
              <h5 class="text-center mb-lg-10">{{ $t('general.there_is_content_available') }}</h5>
            </template>
          </div>
          <div v-if="Number(taskStatus) === 8 || Number(taskStatus) === 12" class="d-flex justify-content-center mt-5">
            <modal :adaptive="true" height="auto" name="designer-image-modal" width="400">
              <div class="card card-custom">
                <div class="card-body card-body-scroll">
                  <photo-recorder :model.sync="newContent"></photo-recorder>
                  <file-input
                      ref="imageFileInput"
                      :model.sync="newContent"
                      :title="$t('editSample.image')"
                      accept="image/*"
                      name="content-image"
                  >
                    <template v-slot:inputDesign>
                      <div class="w-100 d-flex justify-content-center"
                           @click="$refs.imageFileInput.openFileInput()"
                      >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                      </div>
                    </template>
                  </file-input>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button class="py-2 btn btn-sm btn-outline-danger  font-weight-bold cursor-pointer mr-3"
                          type="button"
                          @click="closeModalByName('designer-image-modal')">
                    {{ $t('editSample.close') }}
                  </button>
                  <button class="py-2 btn btn-sm btn-primary font-weight-bold cursor-pointer"
                          type="button"
                          @click="addContent(3, 'designer-image-modal')">{{
                      $t('editSample.save')
                    }}
                  </button>
                </div>
              </div>
            </modal>
            <span @click="openModal('designer-image-modal')" v-html="getIconByKey('icons.meeting.add-image', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            <modal :adaptive="true" height="auto" name="designer-video-modal" width="400">
              <div class="card card-custom">
                <div class="card-header py-4">
                  <h3>{{ $t("editSample.please_select_video_to_upload") }}</h3>
                </div>
                <div class="card-body card-body-scroll">
                  <video-recorder :model.sync="newContent"></video-recorder>
                  <file-input
                      ref="videoFileInput"
                      :model.sync="newContent"
                      :title="$t('editSample.video')"
                      accept="video/*"
                      name="content-video"
                  >
                    <template v-slot:inputDesign>
                      <div class="w-100 d-flex justify-content-center"
                           @click="$refs.videoFileInput.openFileInput()"
                      >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                      </div>
                    </template>
                  </file-input>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button class="btn btn-sm btn-outline-danger font-weight-bold cursor-pointer mr-3"
                          type="button"
                          @click="closeModalByName('designer-video-modal')">
                    {{ $t('editSample.close') }}
                  </button>
                  <button ref="submitVideo"
                          class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                          type="button"
                          @click="addContent(2, 'designer-video-modal')">{{ $t('editSample.save') }}
                  </button>
                </div>
              </div>
            </modal>
            <span @click="openModal('designer-video-modal')" v-html="getIconByKey('icons.sample.add-video', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            <modal
                :adaptive="true"
                height="auto"
                name="designer-audio-modal"
                width="400"
            >
              <div class="card card-custom">
                <div class="card-body card-body-scroll">
                  <audio-recorder :model.sync="newContent"></audio-recorder>
                  <file-input
                      ref="videoFileInput"
                      :model.sync="newContent"
                      :title="$t('editSample.image')"
                      accept="audio/*"
                      name="content-image"
                  >
                    <template v-slot:inputDesign>
                      <div class="w-100 d-flex justify-content-center"
                           @click="$refs.videoFileInput.openFileInput()"
                      >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                      </div>
                    </template>
                  </file-input>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button class="btn btn-sm btn-outline-danger font-weight-bold cursor-pointer mr-3"
                          type="button"
                          @click="closeModalByName('designer-audio-modal')">
                    {{ $t('editSample.close') }}
                  </button>
                  <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                          type="button"
                          @click="addContent(4, 'designer-audio-modal')">{{ $t('editSample.save') }}
                  </button>
                </div>
              </div>
            </modal>
            <span @click="openModal('designer-audio-modal')" v-html="getIconByKey('icons.sample.add-audio', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            <modal
                :adaptive="true"
                height="auto"
                name="designer-text-modal"
                width="400"
            >
              <div class="card card-custom">
                <div class="card-header py-4">
                  <h3>{{ $t("editSample.please_enter_note") }}</h3>
                </div>
                <div class="card-body card-body-scroll">
                  <textarea-input
                      :model.sync="newContent"
                      :placeholder="$t('editSample.please_enter_note')"
                      :title="$t('editSample.note')"
                      name="product-note"
                  ></textarea-input>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button class="btn btn-sm btn-outline-danger font-weight-bold cursor-pointer mr-3"
                          type="button"
                          @click="closeModalByName('designer-text-modal')">
                    {{ $t('editSample.close') }}
                  </button>
                  <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                          type="button"
                          @click="addContent(1, 'designer-text-modal')">{{ $t('editSample.save') }}
                  </button>
                </div>
              </div>
            </modal>
            <span @click="openModal('designer-text-modal')" v-html="getIconByKey('icons.meeting.add-note', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
          </div>
          <template v-if="Number(taskStatus) === 8">
            <div class="w-100 d-flex justify-content-center my-4">
              <button-with-icon
                  :disabled="!isValidToSubmitDesigner && is_submitting && !isUserGranted('Staff', ['modelHouseChief', 'modelHouseStaff'], false)"
                  :icon-name="null"
                  :text="$t('model_house.save_and_created_instruction_for_planter')"
                  class="font-weight-boldest mr-4"
                  size="lg"
                  @onClick="finishDesignerTask"
              ></button-with-icon>
            </div>
          </template>
        </template>
        <template v-if="Number(taskStatus) === 9 && isUserGranted('Staff', ['modelHouseChief'], false)">
          <div class="col-12 row d-flex justify-content-center mt-7">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <custom-multi-select
                  :max="1"
                  :model.sync="designerForm.planter_id"
                  :not-list="true"
                  :options="planterOptions"
                  :placeholder="$t('model_house.please_select_planter')"
                  :title="$t('model_house.planter')"
                  name="planter_id"
              ></custom-multi-select>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center my-4">
            <button-with-icon
                :disabled="Number(designerForm.planter_id) === 0 || this.is_submitting || !isUserGranted('Staff', ['modelHouseChief'], false)"
                :icon-name="null"
                :text="$t('model_house.save_and_create_instruction_for_planter')"
                class="font-weight-boldest"
                size="lg"
                @onClick="sendToPlanter"
            ></button-with-icon>
          </div>
        </template>


        <template v-if="Number(taskStatus) >= 10">
          <div class="w-100 d-flex justify-content-center my-4">
            <p><b>İstenen:</b>{{amountInformations.amount}}  <b>Giriş Yapılan Miktar:</b>{{amountInformations.delivered_amount}}  </p>
          </div>
          <div class="w-100 d-flex justify-content-center my-4">
            <number-input
                v-if="(Number(taskStatus) === 12 || Number(taskStatus) === 10) && !toRepeatProcess"
                :helper-text="$t('model_house.completed_sample')"
                :is-valid=" Number(sample.delivered_amount) !==  0 ? true : null"
                :model.sync="sample.delivered_amount"
                :placeholder="$t('model_house.completed_sample')"
                :title="$t('model_house.completed_sample')"
            ></number-input>
          </div>
          <div class="w-100 d-flex justify-content-center my-4">
            <button-with-icon
                v-if="Number(taskStatus) === 13 "
                :icon-name="null"
                :text="$t('model_house.download_as_pdf')"
                class="font-weight-boldest mr-4"
                size="lg"
                @onClick="openInNewTab(currentItem.model_house_pdf_link)"
            ></button-with-icon>
            <button-with-icon
                v-if="Number(taskStatus) === 13"
                :disabled="is_submitting"
                :icon-name="null"
                :text="$t('model_house.print_as_tag')"
                class="font-weight-boldest"
                size="lg"
                @onClick="openInNewTab(currentItem.model_house_tag_pdf_link)"
            ></button-with-icon>

            <button-with-icon
                v-if="(Number(taskStatus) === 12 || Number(taskStatus) === 10) && !toRepeatProcess && isUserGranted('Staff', ['modelHouseChief'], false)"
                :disabled="is_submitting || !(sample.delivered_amount && sample.delivered_amount > 0)"
                :icon-name="null"
                :text="$t('model_house.finish_task')"
                class="font-weight-boldest"
                size="lg"
                @onClick="finishProcess"
            ></button-with-icon>

            <button-with-icon
                v-if="Number(taskStatus) === 12 && toRepeatProcess"
                :disabled="is_submitting"
                :icon-name="null"
                :text="$t('sample.save_and_create_instruction')"
                class="font-weight-boldest"
                size="lg"
                @onClick="restartProcess"
            ></button-with-icon>
          </div>
        </template>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {mapGetters} from 'vuex'
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import ButtonWidthIcon from "@/assets/components/widget/ButtonWithIcon";
import {CREATE_ITEM, DELETE_ITEM_BY_ID, GET_ITEMS, PATCH, UPDATE_ITEM_BY_ID} from "@/core/services/store/REST.module";
import defaultSewingTable from "./format/default-sweing";
import AudioRecorder from "@/assets/components/recorder/AudioRecorder";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import MeetingImageHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingImageHolderCard";
import MeetingVideoHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingVideoHolderCard";
import MeetingAudioPlayerHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingAudioPlayerHolderCard";
import FileInput from "@/assets/components/inputs/FileInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import SampleImageSelectorModal from "@/view/pages/samples/layout/edit/SampleImageSelectorModal";
import AudioPlayer from "@/assets/components/mediaPlayer/AudioPlayer";
import MeetingTextHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingTextHolderCard";
import QrcodeVue from "qrcode.vue";
import {v4 as uuidv4} from "uuid";
import VideoRecorder from "@/assets/components/recorder/VideoRecorder";
import XLSX from 'xlsx'
import pdf from 'vue-pdf'
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";

export default {
  name: "ModelHouseTaskForSample",
  props: {
    sample: {
      required: true,
    },
    meeting: {
      required: true,
    },
    taskStatus: {
      required: true,
    },
    modelHouseId: {
      required: true,
    },
    amountInformations:{
      required: true,
    }
  },
  components: {
    ButtonWithIcon,
    DashboardBox,
    AudioRecorder,
    PhotoRecorder,
    InfoTooltip,
    MeetingImageHolderCard,
    MeetingVideoHolderCard,
    MeetingAudioPlayerHolderCard,
    FileInput,
    TextareaInput,
    TextInput,
    SelectInput,
    MultipleSelectInput,
    DatePickerInput,
    NumberInput,
    SampleImageSelectorModal,
    AudioPlayer,
    MeetingTextHolderCard,
    QrcodeVue,
    CustomMultiSelect,
    ButtonWidthIcon,
    VideoRecorder,
    pdf,
    NumberInputWithUnit
  },
  computed: {
    ...mapGetters({}),
    isValidToSubmitReceiveMaterial() {
      if (Number(this.form.product_description) === 0) return false;
      if (Number(this.form.unit_id) === 0) return false;
      if (Number(this.form.fabric_roll) === 0) return false;
      if (Number(this.form.net_amount) === 0) return false;
      if (this.form.part_number === null || this.form.part_number === '') return false;
      if (this.form.product_color === null || this.form.product_color === '') return false;
      if (this.form.product_name === null || this.form.product_name === '') return false;
      if (this.form.order_number === null || this.form.order_number === '') return false;
      return true;
    },
    isValidToSubmitDesigner() {
      if (Number(this.selectedEntry) === 2) {
        return this.excels.length > 0;
      } else if (Number(this.selectedEntry === 3)) {
        return this.pdfs.length > 0;
      } else if (Number(this.selectedEntry === 4)) {
        return this.docs.length > 0;
      }else if (this.selectedEntry === null) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      src: null,
      numPages: undefined,
      // ROLES
      authorizedRolesToSubmitMaterial: [1, 7, 8],
      authorizedRolesToPerformSuperActions: [1, 7],
      authorizedRoleToAssignPlanter: [1, 7],

      is_submitting: false,

      toRepeatProcess: false,
      showPrintButtons: false,

      currentExcelInfo: {},
      currentDocInfo: {},
      currentPDF: {
        blob: null,
      },

      designerOptions: [],
      planterOptions: [],
      newSewingSizeRow: null,
      contents: [],
      excels: [],
      currentSelectedExcel: null,
      currentSelectedDoc: null,
      currentSelectedPDF: null,
      pdfs: [],
      docs: [],
      newContent: null,

      selectedEntry: null,
      currentItem: null,
      sendToDesignerForm: {
        designer_id: null,
      },
      designerForm: {
        sewing_info: defaultSewingTable,
        planter_id: null,
      },
      form: {
        product_description: null,
        unit_id: null,
        net_amount: null,
        product_name: null,
        fabric_roll: null,
        product_color: null,
        part_number: null,
        planter_id: null,
        order_number: null,
      },
      unitOptions: [],
      productDescriptionOptions: [],
      userOptions: [],
    };
  },
  methods: {
    async convertLinkToBlob(link) {
      let response = await fetch(link);
      return await response.blob();
    },
    downloadDoc(doc){
      if(doc.file){
        const url = window.URL.createObjectURL(new Blob([doc.file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc.name);
        document.body.appendChild(link);
        link.click();
      }else{
        const link = document.createElement('a');
        link.href = doc.blob;
        link.setAttribute('download', doc.name);
        document.body.appendChild(link);
        link.click();
      }
    },

    submissionForReceiveMaterials() {
      if (this.isUserGranted('ModelHouse', ['update'])) {
        if (this.is_submitting) return;
        this.is_submitting = true;
        let promises = [], self = this;
        promises.push(this.$store.dispatch(PATCH, {
          url: `api/model-house/change-status/${this.modelHouseId}`,
          contents: {
            status_id: 8,
          }
        }));
        promises.push(this.$store.dispatch(UPDATE_ITEM_BY_ID, {
          url: `api/model-house/${this.modelHouseId}`,
          contents: this.form,
        }));

        Promise.all(promises).then(results => {
          if (results[0].status && results[1].status) {
            self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(_ => {
              self.is_submitting = false;
              self.$router.go(-1);
            })
          } else {
            self.is_submitting = false;
            self.sweetAlertError(self.$t('general.internal_error'));
          }
        })
      }

    },
    finishProcess() {
      if (this.isUserGranted('Staff', ['modelHouseChief'])) {
        if (this.is_submitting) return;
        this.is_submitting = true;
        let promises = [], self = this;
        promises.push(this.$store.dispatch(UPDATE_ITEM_BY_ID, {
          url: `api/samples/${this.$route.params.id}/delivery-count`,
          contents: this.sample
        }));
        let totals=Number(this.amountInformations.delivered_amount)+Number(this.sample.delivered_amount);
        if(totals>=this.sample.amount){
            promises.push(this.$store.dispatch(PATCH, {
            url: `api/model-house/change-status/${this.modelHouseId}`,
            contents: {
              status_id: 13,
            }
          }));
        }
        
        Promise.all(promises).then((results) => {
          if(totals>=this.sample.amount){
              if (results[0].status && results[1].status) {
              self.sweetAlertSuccess(self.$t('general.successfully_saved')).then((_) => {
                self.is_submitting = false;
                self.$router.go(-1);
              })
            }
          }else{
            if (results[0].status) {
              self.sweetAlertSuccess(self.$t('general.successfully_saved')).then((_) => {
                self.is_submitting = false;
                self.$router.go(-1);
              })
            }
          }

          
        })
      }
    },
    restartProcess() {
      if (this.isUserGranted('Staff', ['modelHouseChief'])) {
        if (this.is_submitting) return;
        this.is_submitting = true;
        let promises = [], self = this;
        promises.push(this.$store.dispatch(PATCH, {
          url: `api/model-house/change-status/${this.modelHouseId}`,
          contents: {
            status_id: 8,
          }
        }));
        Promise.all(promises).then((results) => {
          if (results[0].status) {
            self.sweetAlertSuccess(self.$t('general.successfully_saved')).then((_) => {
              self.is_submitting = false;
              self.$router.push({name: 'model_house.index'});
            })
          }
        })
      }
    },
    finishDesignerTask() {
      if (this.isUserGranted('Staff', ['modelHouseChief', 'modelHouseStaff'])) {
        if (this.is_submitting) return;
        this.is_submitting = true;

        let promises = [], self = this, form = new FormData();

        promises.push();
        this.$store.dispatch(CREATE_ITEM, {
          url: `api/model-house/${this.modelHouseId}/planting-instructions`,
          contents: {
            type_id: this.selectedEntry,
          },
          acceptPromise: true,
        }).then((result) => {
          for (let i = 0; i < self.contents.length; i++) {
            if ('foreign_id' in self.contents[i]) continue;
            let tempForm = new FormData();
            tempForm.append('content', self.contents[i].data);
            tempForm.append('type', self.contents[i].content_type_id);
            tempForm.append('is_instruction', '0');
            promises.push(self.$store.dispatch(CREATE_ITEM, {
              url: `api/model-house/${this.modelHouseId}/content`,
              contents: tempForm
            }));
          }
          if (Number(self.selectedEntry) === 1) {
            let tempForm = new FormData();
            tempForm.append('content', JSON.stringify(self.designerForm));
            tempForm.append('type', '1');
            tempForm.append('is_instruction', '1');
            tempForm.append('type_id', '1');
            promises.push(self.$store.dispatch(CREATE_ITEM, {
              url: `api/model-house/${this.modelHouseId}/content`,
              contents: tempForm
            }));
          } 
          else if (Number(self.selectedEntry) === 1) {
            let tempForm = new FormData();
            tempForm.append('content', JSON.stringify(self.designerForm));
            tempForm.append('type', '1');
            tempForm.append('is_instruction', '1');
            tempForm.append('type_id', '1');
            promises.push(self.$store.dispatch(CREATE_ITEM, {
              url: `api/model-house/${this.modelHouseId}/content`,
              contents: tempForm
            }));
          }else {
            let temp=null;
            if(Number(self.selectedEntry) === 2){
              temp=self.excels;
            }
            else if(Number(self.selectedEntry) === 3){
              temp=self.pdfs;
            }else{
              temp=self.docs;
            }
            for (let i = 0; i < temp.length; i++) {
              if ('foreign_id' in temp[i]) continue;
              let tempForm = new FormData();
              tempForm.append('content', temp[i].file);
              tempForm.append('type', '5');
              tempForm.append('is_instruction', '1');
              tempForm.append('type_id', self.selectedEntry.toString());
              promises.push(self.$store.dispatch(CREATE_ITEM, {
                url: `api/model-house/${this.modelHouseId}/content`,
                contents: tempForm
              }));
            }
          }
          Promise.all(promises).then((results) => {
            if (results[0].status) {
              self.sweetAlertSuccess(self.$t('general.successfully_saved')).then((_) => {
                self.is_submitting = false;
                self.$router.push({name: 'model_house.index'});
              })
            }
          })
        })
      }
    },
    deleteContent(id) {
      if (this.isUserGranted('ModelHouse', ['update'])) {
        let tempContentObjects = this.convertArrayToObjectByKey([...this.contents], 'id'), self = this;
        if (id in tempContentObjects) {
          if ('foreign_id' in tempContentObjects[id]) {
            this.sweetAlertLoading();
            this.is_submitting = true;
            this.$store.dispatch(DELETE_ITEM_BY_ID, {
              url: `api/model-house/${this.modelHouseId}/content/${tempContentObjects[id].id}`
            }).then((result) => {
              self.sweetAlertClose();
              if (result.status) {
                self.contents.splice(Object.keys(tempContentObjects).indexOf(id.toString()), 1);
                self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
              } else {
                self.sweetAlertError(self.$t('general.internal_error'))
              }
              self.is_submitting = false;
            })
          }
        }
      }
    },
    openPDF(index) {
      this.currentPDF = this.pdfs[index];
      this.src = pdf.createLoadingTask(this.currentPDF.file_link);

      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages;
      });

      this.$modal.show('display-pdf-modal')
    },
    sendToPlanter() {
      if (this.isUserGranted('Staff', ['modelHouseChief'])) {
        if (this.is_submitting) return;
        this.is_submitting = true;

        let self = this;
        this.sweetAlertLoading();
        this.$store.dispatch(PATCH, {
          url: `api/model-house/change-status/${this.modelHouseId}`,
          contents: {
            status_id: 10,
            planter_id: this.designerForm.planter_id,
          }
        }).then((result) => {
          self.sweetAlertClose();
          if (result.status) {
            self.sweetAlertSuccess(self.$t('general.successfully_updated')).then(_ => {
              this.is_submitting = false;
              self.$router.push({name: 'model_house.index'});
            })
          } else {
            self.sweetAlertError(self.$t('general.internal_error'));
          }
        })
      }
    },
    getHeaderRow(sheet) {
      let headers = [], range = XLSX.utils.decode_range(sheet['!ref']);
      let C, R = range.s.r; /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        let cell = sheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    fixData(data) {
      let o = "", l = 0, w = 10240;
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    openExcel(index) {
      this.currentExcelInfo = {
        headers: [],
        body: [],
      }
      let file = this.excels[index].file, self = this;

      if ('foreign_id' in this.excels[index]) {
        this.excels[index].blob.arrayBuffer().then(data => {
          let results,
              fixedData = self.fixData(data),
              workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
              firstSheetName = workbook.SheetNames[0],
              worksheet = workbook.Sheets[firstSheetName];
          self.currentExcelInfo.headers = self.getHeaderRow(worksheet);
          results = XLSX.utils.sheet_to_json(worksheet);
          self.currentExcelInfo.body = results;
          self.$modal.show('display-excel-modal')
        })
      } else {
        let reader = new FileReader(), name = file.name;
        reader.onload = function (e) {
          let results,
              data = e.target.result,
              fixedData = self.fixData(data),
              workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
              firstSheetName = workbook.SheetNames[0],
              worksheet = workbook.Sheets[firstSheetName];
          self.currentExcelInfo.headers = self.getHeaderRow(worksheet);
          results = XLSX.utils.sheet_to_json(worksheet);
          self.currentExcelInfo.body = results;
          self.$modal.show('display-excel-modal')
        };
        reader.readAsArrayBuffer(file);
      }
    },
    openDoc(index) {
      this.currentDocInfo = {
        headers: [],
        body: [],
      }
      let file = this.docs[index].file, self = this;

      if ('foreign_id' in this.docs[index]) {
        this.docs[index].blob.arrayBuffer().then(data => {
          let results,
              fixedData = self.fixData(data),
              workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
              firstSheetName = workbook.SheetNames[0],
              worksheet = workbook.Sheets[firstSheetName];
          self.currentDocInfo.headers = self.getHeaderRow(worksheet);
          results = XLSX.utils.sheet_to_json(worksheet);
          self.currentDocInfo.body = results;
          self.$modal.show('display-docs-modal')
        })
      } else {
        let reader = new FileReader(), name = file.name;
        reader.onload = function (e) {
          let results,
              data = e.target.result,
              fixedData = self.fixData(data),
              workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
              firstSheetName = workbook.SheetNames[0],
              worksheet = workbook.Sheets[firstSheetName];
          self.currentDocInfo.headers = self.getHeaderRow(worksheet);
          results = XLSX.utils.sheet_to_json(worksheet);
          self.currentDocInfo.body = results;
          self.$modal.show('display-docs-modal')
        };
        reader.readAsArrayBuffer(file);
      }
    },
    removeSelectedFile(index, type = 1) {

      if (this.isUserGranted('ModelHouse', ['update'])) {
        let self = this;
        this.sweetAlertConfirm(this.$t('model_house.are_you_sure_about_this')).then((result) => {
          if (result) {
            let contentID = null;
            if (type === 1) {
              if ('foreign_id' in self.excels[index]) {
                contentID = {...self.excels[index]}.id;
              }
              self.excels.splice(index, 1);
            } 
            else if(type === 2){
              if ('foreign_id' in self.pdfs[index]) {
                contentID = {...self.pdfs[index]}.id;
              }
              self.pdfs.splice(index, 1);
            }else{
              if ('foreign_id' in self.docs[index]) {
                contentID = {...self.docs[index]}.id;
              }
              self.docs.splice(index, 1);
            }
            if (contentID) {
              self.$store.dispatch(DELETE_ITEM_BY_ID, {
                url: `api/model-house/${self.modelHouseId}/content/${contentID}`
              })
            }
            self.sweetAlertSuccess(this.$t('general.successfully_deleted'));
          }
        })

      }

    },
    onPDFUpload(payload) {
      this.pdfs = [...this.pdfs, ...payload.files];
      this.currentSelectedPDF = null;
    },
    onExcelUpload(payload) {
      this.excels = [...this.excels, ...payload.files]
      this.currentSelectedExcel = null;
    },
    onDocUpload(payload) {
      this.docs = [...this.docs, ...payload.files]
      this.currentSelectedDoc = null;
    },
    openModal(name = null) {
      this.newContent = null
      this.$modal.show(name);
    },
    addContent(type, modalName) {
      if (this.isUserGranted('ModelHouse', ['update'])) {
        if (Number(this.taskStatus) === 12) {
          this.toRepeatProcess = true;
        }
        if (this.newContent == null) {
          this.sweetAlertWarning(this.$t('createOriginalSample.please_select_file_or_finished_record_before_saving'))
          return;
        }
        this.$modal.hide(modalName);
        let tempContent = {
          id: uuidv4(),
          content_type_id: type,
          data: this.newContent,
          user: {
            name: this.authUser.name,
            avatar_file: this.authUser.avatar,
          }
        };
        if (Number(type) === 1) {
          tempContent['content'] = this.newContent;
        } else if (Number(type) === 2) {
          tempContent['file_link'] = window.URL.createObjectURL(this.newContent);
        } else if (Number(type) === 3) {
          tempContent['file_link'] = window.URL.createObjectURL(this.newContent);
        } else if (Number(type) === 4) {
          tempContent['file_link'] = window.URL.createObjectURL(this.newContent);
        }
        this.contents.push(tempContent);
      }
    },
    removeSewingSizeRowByKey(value) {
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then((result) => {
        if (result) {
          for (let key in this.designerForm.sewing_info) {
            delete this.designerForm.sewing_info[key].sizes[value];
          }
          this.newSewingSizeRow = '1';
          this.newSewingSizeRow = null;
        }
      });
    },
    addNewSewingSizeRow() {
      if (this.newSewingSizeRow.length === 0 || this.newSewingSizeRow === '') return;
      if (Object.keys(this.designerForm.sewing_info.A04).indexOf(this.newSewingSizeRow) < 0) {
        for (let key in this.designerForm.sewing_info) {
          this.designerForm.sewing_info[key].sizes[this.newSewingSizeRow] = null;
        }
        this.newSewingSizeRow = null;
      } else {
        this.sweetAlertWarning(this.$t('general.given_size_is_already_existed'))
      }
    }
  },
  mounted() {
    let self = this, promises = [];
    if (!this.modelHouseId) return;
    if (true) {
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/planters',
        acceptPromise: true,
        showLoading: false,
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: `api/model-house/${this.modelHouseId}`,
        acceptPromise: true,
        showLoading: false,
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/units',
        acceptPromise: true,
      }));

      this.productDescriptionOptions = {
        "1": this.$t('waybill.by_scanning_qr_code'),
        "2": this.$t('waybill.by_entering_info')
      };

      Promise.all(promises).then(results => {
        if (results[0].status) {
          self.planterOptions = self.convertArrayToObjectByKey(results[0].data, 'id', 'name');
        }
        if (results[1].status) {
          self.currentItem = results[1].data;
          self.form.product_name = results[1].data.product_name;
          self.selectedEntry = Number(results[1].data.type_id);
          if (Number(self.taskStatus) !== Number(results[1].data.status_id)) {
            self.sweetAlertError(self.$t('general.invalid_request')).then(_ => {
              self.$router.replace({name: 'model_house.tasks'});
            })
          }

          for (let i = 0; i < results[1].data.contents.length; i++) {
            let current = results[1].data.contents[i];
            if (results[1].data.file_ids !== null && results[1].data.file_ids !== '') {
              if (JSON.parse(results[1].data.file_ids).includes(current.id)) {
                if (self.selectedEntry === 1 && Number(results[1].data.contents[i].content_type_id) === 1) {
                  if (current.content !== null && current.content !== '') {
                    self.designerForm = JSON.parse(current.content);
                  }
                } else if (self.selectedEntry === 2 && Number(results[1].data.contents[i].content_type_id) === 5) {
                  self.excels.push({...current, ...{name: self.randomStr(10, false) + '.xlxs'}});
                  let index = self.excels.length - 1;
                  self.convertLinkToBlob(current.file_link).then(blob => {
                    self.excels[index].blob = blob;
                  })

                } 
                else if (self.selectedEntry === 4 && Number(results[1].data.contents[i].content_type_id) === 5) {
                  self.docs.push({...current, ...{name: self.randomStr(10, false) + '.docx', blob: current.file_link}});
                }else if (self.selectedEntry === 3 && Number(results[1].data.contents[i].content_type_id) === 5) {
                  self.pdfs.push({...current, ...{name: self.randomStr(10, false) + '.pdf', blob: current.file_link}});
                }
              } else {
                self.contents.push({
                  ...current
                })
              }
            } else {
              self.contents.push({
                ...current
              })
            }
          }
        }
        if (results[2].status) {
          self.unitOptions = {'null': self.$t('model_house.unit_type'), ...self.convertArrayToObjectByKey(results[2].data, 'id', 'translations.0.name')};
        }
      })
      this.form.order_number = this.sample.collection_number;
    }
  }
}
</script>

<style scoped>

</style>
